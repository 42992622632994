import * as React from 'react';
import { BsInstagram, BsTwitter, BsDiscord } from "react-icons/bs";
import './style.scss'
const Community = () => {
    return (
        <div className='community-section' id="community">
            <h1>JOIN US!</h1>
            <div className='community-container'>
                <a href='https://www.instagram.com/walksoffame.co/' target="_blank" rel="noopener noreferrer"><BsInstagram size="30px"/></a>
                <a href='https://twitter.com/walksoffameWOF' target="_blank" rel="noopener noreferrer"><BsTwitter size="30px"/></a>
                <a href='https://discord.gg/4RmPYdHJb5' target="_blank" rel="noopener noreferrer"><BsDiscord size="30px"/></a>
            </div>
        </div>
    )
}
export default Community;