import * as React from 'react';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import './style.scss'
const roadmapData = [
    {
        title: 'OCTOBER 2021',
        content: 'Planning and Proof of Concept',
    },
    {
        title: 'NOVEMBER 2021',
        content: 'Assembling of Team',
    },
    {
        title: 'DECEMBER 2021',
        content: 'Developing of Social Media platforms',
    },
    {
        title: 'JANUARY 2022',
        content: 'Community Build',
    },
    {
        title: 'FEBRUARY 2022',
        content: 'Design and Coding of NFT\'S',
    },
    {
        title: 'MARCH 2022',
        content: 'First Event',
    },
    {
        title: 'MAY 2022',
        content: 'Implementation of NFT',
    },
    {
        title: 'JUNE 2022',
        content: 'Metaverse',
    },
    {
        title: 'JULY 2022',
        content: 'TBD',
    },
]
const Roadmap = () => {
    return (
        <div className='roadmap-component' id="roadmap">
            <h1>Roadmap</h1>
            <VerticalTimeline >
                {roadmapData.map((item, index) =>
                    <VerticalTimelineElement
                        className="vertical-timeline-element--work"
                        iconStyle={{ background: '#DBA514', color: '#fff', border: '4px solid red', }}
                        key={`verticalline-${index}`}

                    >
                        <div className='map-container'>
                            <h3 className="vertical-timeline-element-title">{item.title}</h3>
                            <p className="vertical-timeline-element-subtitle">{item.content}</p>
                        </div>
                    </VerticalTimelineElement>
                )}
            </VerticalTimeline>
        </div>
    )
}
export default Roadmap;