import './App.css';
import Particles from "react-tsparticles";
import Home from './pages/Home';
import About from './pages/About';
import Collection from './pages/Collections';
import Roadmap from './pages/Roadmap';
import Team from './pages/Team';
import Faq from './pages/Faq';
import Community from './pages/Community';
import {particleOption} from './data/particle'
function App() {
  const particlesInit = (main) => {
    console.log(main);
  };
  const particlesLoaded = (container) => {
    console.log(container);
  };
  return (
    <div className="App">
      <Particles
        id="tsparticles"
        init={particlesInit}
        loaded={particlesLoaded}
        options={particleOption}
      />
      <Home />
      <About />
      <Collection />
      <Roadmap />
      <Team />
      <Faq />
      <Community />
    </div>
  );
}

export default App;
