import * as React from 'react';
import team1 from '../../assets/images/example.png';
import TeamComponent from '../../components/TeamComponent';
import './style.scss';
const teamMembers = [
    {
        avatar: team1,
        memberName: 'Peter',
        memberJob: 'Chief Art and Marketing',
        memberDescription: 'With over 30 years of experience in Acting, Art and marketing. Peter is praised for his expertise and knowledge.'
    },
    {
        avatar: team1,
        memberName: 'Asaf',
        memberJob: 'Chief of Strategy',
        memberDescription: 'With his extensive knowledge and background in real estate, business, and investments, our team continues to innovate.'
    },
    {
        avatar: team1,
        memberName: 'Hen',
        memberJob: 'Operations Chief',
        memberDescription: 'With his background of running successful business, and his problem solving skills, the moon is closer than ever.'
    },
    {
        avatar: team1,
        memberName: 'David',
        memberJob: 'Tech Chief',
        memberDescription: 'Experience in blockchain, crypto, NFT and Metaverse. He is in charge of technical aspects of this project.'
    },
]
const Team = () => {
    return (
        <div className='team-section' id="team">
            <h1>TEAM</h1>
            <div className='team-container'>
                {teamMembers.map((member, index) => <TeamComponent {...member} key={`team-member-${index}`} />)}
            </div>
        </div>
    )
}
export default Team;