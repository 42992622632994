import * as React from 'react';
import { BsPlusCircleFill, BsFillQuestionCircleFill } from "react-icons/bs";

import './style.scss';
const FaqComponent = ({ title, content }) => {
    const [isShow, setIsShow] = React.useState(false);
    const handleShow = () => {
        setIsShow(!isShow)
    }
    return (
        <div className="faq-container">
            <div className="faq-header" onClick={()=>{handleShow()}}>
                <BsFillQuestionCircleFill className='question-mark'/>
                <p>{title}</p>
                <button type="button" onClick={handleShow}>
                    { <BsPlusCircleFill className={`open-content ${isShow ? 'active' : ''}`} /> }
                </button>
            </div>
            {isShow && (
                <div className="faq-content">
                    <p>{content}</p>
                </div>
            )}
        </div>
    )
};
export default FaqComponent;
