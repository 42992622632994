import * as React from 'react';
import './style.scss';
const CollectionComponent = ({ image, label, content }) => {
    return (
        <div className="collection-item">
            <img src={image} alt={`collection-${label}`} />
            <p className='collection-label'>{label}</p>
            <p className='collection-content'>{content}</p>
        </div>
    )
}
export default CollectionComponent;