import * as React from 'react';
import dayIamge from '../../assets/images/example.png';
import nightImage from '../../assets/images/example.png';
import limitedOne from '../../assets/images/example.png';
import CollectionComponent from '../../components/CollectionComponent';
import './style.scss';
const collectionData = [
    {
        image: dayIamge,
        label: 'Day',
        content:''
    },
    {
        image: nightImage,
        label: 'Night',
        content:''
    },
    {
        image: limitedOne,
        label: 'Limited Edition',
        content:''
    },
]
const Collection = () => {
    return (
        <div className='collection-section' id="collection">
            <h1> Collection </h1>
            <div className='collection-contents'>
                {collectionData.map((item, index) => {
                    return (
                        <CollectionComponent {...item} key={`collection-${index}`} />
                    )
                })}
            </div>
        </div>
    )
};
export default Collection;
