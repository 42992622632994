import * as React from 'react';
import './style.scss'
const aboutData = [
    'Walks of Fame is the first NFT collection that is preserving humanities greatest minds!',
    'Have you ever wondered, what will happen to physical landmarks after we full transition to the Metaverse?',
    'Our goal is to preserve the Hollywood Walk of Fame within the Metaverse, while introducing new versions of the Walk all around the virtual map.',
    'Walks of Fame comprises of 6,000 NFT’S\n We have chosen several different collections to tell the tale of the worlds greatest minds!',
    'Iméra : Day 2,700\n The day collection is classic. Sunset, Palm trees, warm breeze, good vibes and unbeatable energy.',
    'Nýchta : Night 2,700\nThe night collection is where the party starts. Neon lights, vibration of music, the streets are lively and the drinks are out!',
    'These NFT’s will be your exclusive membership card to the Walks of Fame and guarantees your acquisition of the Eidikós NFT ',
    'Eidikós : Special TBD\nThe Special collection is simply Special. With each NFT accompanied by an amazing easter egg, a video file, and an interview with that Special person!',
    'After our successful launch we will go into Q2, where the development of different Walks of Fame around the Metaverse will be implemented.',
]
const About = () => {
    return (
        <div className='about-section' id="about">
            <h1> About </h1>
            <div className='about-content'>
                {
                    aboutData.map((item, index) => <p key={`about-${index}`}>{item}</p>)
                }
            </div>
        </div>
    )
};
export default About;
