import * as React from 'react';
import './style.scss';
import logo from '../../assets/images/logo.png';

const Header = () => {
    const [isOpenMenu, setIsOpenMenu] = React.useState(false);
    const handleMenu = () => {
        setIsOpenMenu(!isOpenMenu);
    }
    return (
        <>
            <div className='header'>
                <a href="#home" className='logo-container'>
                    <img src={logo} className="logo" alt="logo" />
                </a>
                <div className='nav-bar-container'>
                    <ul className='nav-bar'>
                        <li><a href='#about'>ABOUT</a></li>
                        <li><a href='#collection'>COLLECTION</a></li>
                        <li><a href='#roadmap'>ROADMAP</a></li>
                        <li><a href='#team'>TEAM</a></li>
                        <li><a href='#faq'>FAQ</a></li>
                    </ul>
                </div>
            </div>
            <div className='mobile-header'>
                <a href='#home'>
                    <img src={logo} className="mobile-logo" alt="logo" />
                </a>
                {
                    !isOpenMenu ? <span className={`menu-trigger`} onClick={() => { handleMenu() }} /> :
                        <span className={`menu-trigger-close`} onClick={() => { handleMenu() }} />
                }
                {
                    isOpenMenu &&
                    <ul className='mobile-nav-bar'>
                        <li><a href='#about'>ABOUT</a></li>
                        <li><a href='#collection'>COLLECTION</a></li>
                        <li><a href='#roadmap'>ROADMAP</a></li>
                        <li><a href='#team'>TEAM</a></li>
                        <li><a href='#faq'>FAQ</a></li>
                    </ul>
                }

            </div>
        </>
    )
}
export default Header;