import * as React from 'react';
import FaqComponent from '../../components/FaqComponent';
import './style.scss';
const faqData = [
    {
        title: '1. What is a NFT?',
        content: 'An NFT is a non-fungible-token, that is linked to the blockchain. It is used to transform and verify a special digital asset.'
    },
    {
        title: '2. What is Walk of Fame?',
        content: 'Walk of Fame (WOF) is rare collection of stars on the Walk of Fame. Our goal is preserve our generations greatest artists.'
    },
    {
        title: '3. When will minting start?',
        content: 'TBD 4.'
    },
    {
        title: '4. How many NFT’s will there be?',
        content: '6,000 NFT’S will be created. 3 different collections.'
    },
    {
        title: '5. Who is the team?',
        content: 'Peter- Chief of Marketing and Design Asaf- Chief of Strategy Hen- Chief of Operations David- Tech Chief'
    },
    {
        title: '6. Which network are we using?',
        content: 'We will be using the Ethereum network'
    },
]
const Faq = () => {
    return (
        <div className='faq-section' id="faq">
            <h1>FAQ</h1>
            <div className='faq-list-container'>
                {faqData.map((item, index) => <FaqComponent {...item} key={`Faq-${index}`} />)}
            </div>
        </div>
    );
}
export default Faq;