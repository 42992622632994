import * as React from 'react';
import './style.scss';
import Header from '../../components/Header';
import nftCollection from '../../assets/images/collection.gif'
const Home = () => {
    return (
        <div className='home-section' id="home">
            <div className='header'>
                <Header />
            </div>
            <div className='home-content'>
                <div className='left-content'>
                    <h1> Welcome to Walks of Fame </h1>
                    <p>
                        Initial collection of 6,000 NFT'S, designed to preserve our
                    </p>
                    <p> greatest minds</p>
                </div>
                <div className='right-content'>
                    <img src={nftCollection} alt="collection" className='sample-collection'/>
                </div>
            </div>
        </div>
    )
};
export default Home;
