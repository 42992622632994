import * as React from 'react';
import './style.scss';
const TeamComponent = ({ avatar, memberName, memberJob }) => {
    return (
        <div className='team-component'>
            <img src={avatar} alt={memberName} className="avartar"/>
            <p className='member-name'>{memberName}</p>
            <p className='member-job'>{memberJob}</p>
        </div>
    )
};
export default TeamComponent;